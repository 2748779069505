    /* FORMS */
    .requiredMark{
        color:red;
    }

    .form fieldset:disabled label{
        color:#9b9b9b;
    }

    .form fieldset:disabled *{
        cursor: not-allowed;
    }


    .disabled {
        cursor: not-allowed;
    }

    /* Hamburger */
    .material-symbols-rounded {
        font-family: 'Material Symbols Rounded';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
    }    

    
    .tooltipSearch {
        position: relative;
        display: inline-block;
    }
  
    /* Tooltip text */
    .tooltipSearch .tooltiptextSearch {
        visibility: hidden;
        width: 140px;
        background-color: black;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        font-size: small;
    
        /* Position the tooltip text - see examples below! */
        position: absolute;
        z-index: 1;
        top: 100%;
        left: 50%;
        margin-left: -60px; 
    }
    
    /* Show the tooltip text when you mouse over the tooltip container */
    .tooltipSearch:hover .tooltiptextSearch {
        visibility: visible;
    }


    /* Barrier */
    .hr-lines{
        position: relative;
        max-width: 540px;
        margin: 20px 0px;
        text-align: center;
    }
      
    .hr-lines:before{
        content:" ";
        height: 1px;
        width: 180px;
        background: rgb(0, 0, 0);
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
    }
      
    .hr-lines:after{
        content:" ";
        height: 1px;
        width: 180px;
        background: rgb(0, 0, 0);
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
    }
      

    /* Ribbon*/
    .cr {
        width: 200px;
        padding: 6px;
        position: absolute;
        text-align: center;
        color: #f0f0f0;
      }
      
    .cr-sticky {
        position: fixed;
    }
    
    /* Positions */
    
    .cr-top    { top: 6px; }
    .cr-bottom { bottom: 25px; }
    .cr-left   { left: -50px; }
    .cr-right  { right: -80px; }
    
    /* Rotations */
    
    .cr-top.cr-left,
    .cr-bottom.cr-right {
        transform: rotate(-45deg);
    }
    
    .cr-top.cr-right,
    .cr-bottom.cr-left {
        transform: rotate(45deg);
    }
    
    /* Colors */
    
    .cr-white     { background-color: #f0f0f0; color: #444; }
    .cr-black     { background-color: #333; }
    .cr-grey      { background-color: #999; }
    .cr-blue      { background-color: #39d; }
    .cr-green     { background-color: #2c7; }
    .cr-turquoise { background-color: #1b9; }
    .cr-purple    { background-color: #95b; }
    .cr-red       { background-color: #e43; }
    .cr-orange    { background-color: #e82; }
    .cr-yellow    { background-color: #ec0; }
      

    /* PREFIX brand name for pro user */
    
    .input-group-addon {
        padding:  12px;
        font-size: 15px;
        font-weight: 400;
        line-height: 1;
        color: #555;
        text-align: center;
        background-color: #eee;
        border: 1px solid #ccc;
        width: 1%;
        white-space: nowrap;
        vertical-align: middle;
        display: table-cell;
        height:40px;
        width:auto;
    }

    #ts-assetID{
        /*width:auto;*/
    }
   
    
    .suffix input {
        border-radius: 4px 0px 0px 4px;
    }
    .suffix .input-group-addon {
        border-left: 0;
        border-radius: 0px 4px 4px 0px;
    }
    .prefix input {
        border-radius: 0px 4px 4px 0px;
    }
    .prefix .input-group-addon {
        border-right: 0;
        border-radius: 4px 0px 0px 4px;
    }
    /*.presuffix input {
        border-radius: 0px;
    }
    .input-group-addon.prefix {
        border-radius: 4px 0px 0px 4px;
        border-right: 0;
    }
    .input-group-addon.suffix {
        border-radius: 0px 4px 4px 0px;
        border-left: 0;
    }*/

    
    .input-group {
        position: relative;
        display: inline-flex;
        border-collapse: separate;
        /*max-width: 360px;*/
    }

    .input-group.prefix {
        margin-bottom: 20px;
        height: 40px;
    }

    /* BRAND SELECTION DROPDOWN MENU */

    #brandSelection:focus-visible{
        border-color: #000;
    }

    #brandSelectingInput{
        border-color:#CECECE;   
        width:250px;

        input {
            border: 0;
            padding: 0;

        }
    }

    #resetID{
        all: unset  !important;
    }

   

    #react-select-3-placeholder{
        color: #7a7a7a !important;
    }


    /* PRO LOOK HAMBURGER MENU CSS*/

    .pro_dropdown_mini_menu{
        left: 0;
        top: 0;
        width:100vw;
        height: 100vh;
        position: fixed;
        background-color: black;
        color: white;
        padding: 100px 30px;
        z-index: 1;
    }

    .pro_dropdown_mini_menu .active{
        color: #be3455;
        text-decoration: underline;
    }
    
    .pro_dropdown_exit{
        z-index: 2;
        width:40px;
        color:white;
    }

    .logout-pro{
        margin-top: 80px;
    }

    .pro_dropdown_mini_menu div {
        font-size: 40px;
    }

    .mobile__sidebar {
        width: 40px;
        display: none;
    }

    @media screen and (max-width:980px) {
        .mobile__sidebar {
            display: inline;
        }

        .mobile-pro {
            display:grid;
            font-family: 'Saira', sans-serif;
            font-weight: 500;
            font-size: 48px;
            background: url("../img/bg-pro-m.jpg") no-repeat center bottom;
            background-size: cover;
            color:white;
            height:fit-content;
            padding-top:2rem;
            padding-bottom:5rem;
        }  
        
        .mobile-pro h1 {
            margin:auto;
            text-align: center;
        }  
    
        .no-mobile-pro{
            display:none;
        }
    }

    @media screen and (min-width:979px){
        .mobile-pro {
            display:none;
        }    
    
        .no-mobile-pro{
            display:inline;
        }

        #logo-mobile{
            display: none;
        }
    }

    /* HAMBURGER MENU CSS */

    .dropdown_mini_menu{
        left: 0;
        top: 0;
        width:100vw;
        height: 100vh;
        position: fixed;
        background-color: white;
        padding: 75px 30px;
    }

    .dropdown_mini_menu .dropdown_exit{
        width:100%;
        font-size: larger;
        color: black;
        text-align: right;
    }

    .dropdown_mini_menu div {
        font-size: 32px;
    }

    .dropdown_mini_menu .exit {
        position: fixed;
        right: 30px;
        top: 30px;
    }

    .dropdown_mini_menu .active{
        color: #be3455;
        text-decoration: underline;
    }

    @media screen and (min-width:670px) {

        .mini_menu{
            display: none;
        }
        
    }

    /* HEADER */

    .site-header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        height:100px;
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100;

        .title {
            font-size: 48px;
            color: var(--primaryColor);
            text-transform: uppercase;
            margin-left: 2rem;
        }
    }

    .logo {
        // position: absolute;
        // top: 0;
        // left: 0;
        height: 100px;
    }

    .nav-badge {
        z-index: 101;
    }
    
    /* NAVIGATION */
    
    .site-navigation {
        // position: absolute;
        // top: 2rem;
        // right: 2rem;
        position: sticky;
        top: 0;
        padding: 1rem 0 1rem 1rem;
        border-radius: 20px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        gap: 1rem;
        margin-right:50px;
        background: #ffffff;
    }
    
    .site-navigation .notifications {
        height: 52px;
        width: 52px;
        border-radius: 50%;
        background: #ffffff;
        border: 1px solid #f2f2f2;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
    }
    
    .site-navigation .notifications img {
        height: 30px;
    }
    
    .site-navigation .notifications .bubble {
        position: absolute;
        top: -8px;
        right: -8px;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        text-align: center;
        line-height: 22px;
        background: var(--red);
        color: #ffffff;
    }
    
    .site-navigation .profile {
        position: relative;
        height: 52px;
        width: 52px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .site-navigation .profile img {
        height: 52px;
        display: block;
    }
    
    .nav {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
    
        .icon {
        display: none;
        }
    
        li {
        padding: 0 1rem;
    
        a {
            display: block;
            padding: .5rem 0;
            font-size: 16px;
            font-weight: 400;
            color: #222222;
            text-transform: uppercase;
            text-decoration: none;
            transition: all .3s;
            border-bottom: 3px solid transparent;
        
            &:hover {
            border-bottom: 3px solid var(--primaryColor);
            color: var(--primaryColor);
            }
        }
    
        &.active a {
            color: var(--primaryColor);
            border-bottom: 3px solid var(--primaryColor);
        }
        }
    }
    
    .profile-dropdown {
        display: none;
        opacity: 0;
        position: absolute;
        top: 100%;
        right: 0;
        width: 180px;
        padding-top: 1rem;
        transition: all .5s;
    }
    
    .profile-dropdown-nav {
        background: #ffffff;
        list-style: none;
        margin: 0;
        padding: 1rem;
        border-radius: 8px;
        position: relative;
        border: 1px solid #000;
    }
    
    .profile-dropdown-nav::before {
        content: "";
        display: block;
        width: 0; 
        height: 0; 
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #000;
        position: absolute;
        bottom: 100%;
        right: 20px;
    }
    
    .profile-dropdown-nav li {
        margin: 6px 2px;
    }
    
    .profile-dropdown-nav a {
        font-size: 12px;
        color: #707070;
        text-decoration: none;
    }
    
    .profile-dropdown-nav a:hover {
        text-decoration: underline;
    }
    
    .link-text{
        color:#000;
        text-decoration: underline;
        font-weight: 500;
    }
    
    .site-navigation .profile:hover > .profile-dropdown {
        display: block;
        opacity: 1;
    }
    
    /* LEFT PARAGRAPH */
    
    .visual-paragraph {
    
        text-align: center;
        
    }

    .paragraph_section{
        margin-bottom: 20px;
        text-align: justify;
        font-size: 16px;
        /*max-width: 500px;*/
    }
    
    /* Icons */
    .column {
        float: left;
        width: 33.33%;
    }
    
    /* Clear floats after the columns */
    .row:after {
        content: "";
        display: table;
        clear: both;
    }
    
    #notActive {
        color: rgb(192, 192, 192);
        text-shadow: 1px 1px 1px #ccc;
    }

    .transactionTitle{
        font-weight: 500;
        font-size: large;
    }
    
    /* METAMASK tooltip */
    
    .tooltip {
        position: relative;
        display: inline-block;
    }
    
    .tooltip .tooltiptext {
        visibility: hidden;
        width: 140px;
        background-color: black;
        color: #fff;
        text-align: center;
        border-radius: 6px;
        padding: 5px 5px;
        font-size: small;
        left: 105%;
    
        /* Position the tooltip */
        position: absolute;
        z-index: 1;
    }
    
    .tooltip:hover .tooltiptext {
        visibility: visible;
    }
    
    /* Contain form */
    
    .contain-form{
        /*min-width: 500px;*/
        height:auto;
    }
    
    .input-protect{
        margin:20px;
    }
    
    /* BUTTON WIDTH */
    
    .full-width{
        width:80%;
    }
    
    /* SPINNER */
    
    .spinner{
        margin-top:20px;
    }
    
    /* FOOTER */
    
    .footer{
        color: rgb(192, 192, 192);
        position: absolute;
        bottom: 20px;
    }
    
    /* PRODUCT DETAILS */
    
    .nft-details {
    
        h2 {
        font-weight: bold;
        margin-top: 0%;
        margin-bottom: 1rem;
        font-size: 42px;
        text-transform: capitalize;
        }

        min-height: calc(100vh - 80px);
    }

    .nft__cards {
        display:flex;
        flex-wrap: wrap;
        background: rgba(0,0,0,.03);
        justify-content: center;
        padding:2rem;
        width:100%;
    }

    .proclaim{
        margin-top: 100px;
    }

    .nft__card {
        width:300px;
        height:320px !important;
        background-color: white;
        padding:1.5rem;
        margin:1rem;
        border-radius: 10px;
        box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #fff;
        transition: all .3s;
    }

    .nft__card:hover{
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
        z-index: 11;
    }

    .nft__card img {
        padding-top:5px;
        object-fit: cover;
        height: 200px !important;
        width:300px !important;
    }
    .nft__card img:hover {
       cursor: pointer;
    }

    .btn-claim{
        align-items: center;
        display:inline;
        min-width: 150px;
    }

    // claim item form

    .nft__cards form{
        padding:1rem 2rem;
    }

    .nft__cards form img{
        height:160px;
        width:160px;
    }

    #claim-nft-back{
        display:inline;
        margin:0rem 2rem;
        justify-content: unset !important;
        float: right;
    }

    /* PRODUCT DETAILS END */
    .nft-section {
        margin-bottom: 2rem;
        color: #222222;
    
        img {
        height: 300px;
        width: 300px;
        border-radius: 10px;
        object-fit: contain;
        /*margin-left: -4rem;*/
        }

        h3 {
        font-size: x-large;
        font-weight: 500;
        }

        h2 {
            font-weight: 500;
            text-decoration: underline;
        }
    
        .col{
            font-weight: 450; 
            margin:0.5rem 0rem;
            display:flex;
        }

        .col .label{
            min-width:200px;
        }

        ul{
            padding-left: 2rem;
        }
    }

    .descriptions-wrap{
        width:100%;
    }

    .accordionSpace{
        margin-bottom: 8px;
    }

    .nft-content-title{
        font-size: 16px;
        font-weight: 300;
        font-weight: 300;
        font-size: 24px;
        position: relative;

        &::before {
            content: "";
            display: block;
            height: 12px;
            width: 12px;
            background-color: #222222;
            position: absolute;
            top: 12px;
            left: 4px;
            opacity: .15;
        }
    
        &::after {
            content: "";
            display: block;
            height: 6px;
            width: 6px;
            background-color: #222222;
            position: absolute;
            left: 15px;
            top: 6px;
            opacity: .25;
        }
    }
    
    .nft-content {
        margin:2rem;
        font-size: 16px;
        font-weight: 300;
        max-width: 80%;
    
        h3 {
        margin-bottom: 1rem;
        font-weight: 300;
        font-size: 24px;
        position: relative;
    
        &::before {
            content: "";
            display: block;
            height: 12px;
            width: 12px;
            background-color: #222222;
            position: absolute;
            left: -16px;
            top: 0px;
            opacity: .15;
        }
    
        &::after {
            content: "";
            display: block;
            height: 6px;
            width: 6px;
            background-color: #222222;
            position: absolute;
            left: -4px;
            top: -6px;
            opacity: .25;
        }
        }
    
        ul {
        margin-left: 1.5rem;
        }
    
        .col {
        margin-bottom: .5rem;
        }

        .col div{
            overflow-wrap: anywhere; 
        }
    
        .label {
        font-size: 14px;
        font-weight: 500;
        display: inline-block;
        margin-right: 8px;
        }

        a{
            text-decoration: underline;
            line-break: anywhere;
        }
    }
    
    
    
    .flex-container-details {
        height: 100%;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width:100%;
        margin-top:40px;
    }
    .row-details {
        width:600px;
    }
    .flex-item-details {
        padding: 5px;
        margin: 10px;
        line-height: 20px;
        font-weight: bold;
        font-size: 2em;
        text-align: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    
    /* TOGGLE SWITCH */
    
    div.btn-container{
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }
    
    div.btn-container i{
        display: inline-block;
        position: relative;
        top: -9px;
    }
    
    label {
        font-size: 13px;
        color: #424242;
        font-weight: 500;
    }
    
    .btn-color-mode-switch{
        display: inline-block;
        margin: 0px;
        position: relative;
        margin-bottom: 1.5rem !important;
    }
    
    .btn-color-mode-switch > label.btn-color-mode-switch-inner{
        margin: 0px;
        width: 206px; /*140*/
        height: 48px;
        background: #F0F0F0;
        color: #9B9B9B;
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease;
        /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
        display: block;
    }
    
    .btn-color-mode-switch > label.btn-color-mode-switch-inner:before{
        content: attr(data-on);
        position: absolute;
        font-size: 16px;
        line-height: 52px;
        font-weight: 500;
        top: 0px;
        right: 17px;
    
    }
    
    .btn-color-mode-switch > label.btn-color-mode-switch-inner:after{
        content: attr(data-off);
        width: 106px; /*70*/
        height: 44px; /*16*/
        line-height: 46px;
        background: #ffffff;
        color: #231F20;
        font-size: 16px;
        border-radius: 6px;
        position: absolute;
        left: 2px;
        top: 2px;
        text-align: center;
        transition: all 0.3s ease;
        padding: 0px 0px;
    }
    
    .btn-color-mode-switch > .alert{
        display: none;
        background: #FF9800;
        border: none;
        color: #fff;
    }
    
    .btn-color-mode-switch input[type="checkbox"]{
        cursor: pointer;
        width: 50px;
        height: 25px;
        opacity: 0;
        position: absolute;
        top: 0;
        z-index: 1;
        margin: 0px;
    }
    
    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner{
        /*background: #151515;*/
        background: #F0F0F0;
        color: #9B9B9B;
    }
    
    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after{
        content: attr(data-on);
        left: 98px; /*68*/
        background: #ffffff;
        color: #231F20;
    }
    
    .btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before{
        content: attr(data-off);
        right: auto;
        left: 25px;
    }
    
    .btn-color-mode-switch input[type="checkbox"]:checked ~ .alert{
        display: block;
    }
    
    /*mode preview*/
    .dark-preview{
        background: #0d0d0d;
    }
    
    .dark-preview div.btn-container i.fa-sun-o{
        color: #777;
    }
    
    .dark-preview div.btn-container i.fa-moon-o{
        color: #fff;
        text-shadow: 0px 0px 11px #fff;
    }
    
    .white-preview{
        background: #fff;
    }
    
    .white-preview div.btn-container i.fa-sun-o{
        color: #ffa500;
        text-shadow: 0px 0px 16px #ffa500;
    }
    
    .white-preview div.btn-container i.fa-moon-o{
        color: #777;
    }
    
    
    p.by a{
        text-decoration: none;
        color: #000;
    }
    
    .dark-preview p.by a{
        color: #777;
    }
    
    .white-preview p.by a{
        color: #000;
    }
    
    #imagesUpload, #thumbnailUpload{
        /*visibility:hidden;*/
        opacity: 0%;
        border:none;
        padding: 0px;
        height: 10px;
    }
    
    /*#SNLabel{
        margin-top: 74px;
    }

    #SNLabelPro{
        margin-top: 58px;
    }*/
    
    
    #ts-assetID{
        max-height: 40px;
        /*margin-top: 40px;*/
    }
    
    #ts-assetID-verify{
        margin-bottom: 54px;
    }
    
    #color_mode:disabled:hover{
        cursor:not-allowed;
    }
    
    button:disabled:hover{
        cursor:not-allowed;
    }
    button:disabled{
        cursor:not-allowed;
        background-color: #b3b3b3;
    }
    .nft-prev{
        background-color: #f7f7f7;
        padding:8px;
        margin-bottom: 20px;
        border-radius: 5px;
        border: 1px solid #ecebeb;
        width: 110px;
        box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;

    }

    #mint-img{
        height: 100px;
        width:100px; 
        margin-top: 16px;
    }
    
    /* MINT */
    div .mint-label{
        color:black;
        font-weight:bolder;
        margin-bottom: 6px;
        font-size: larger;
        font-weight: 500;
        width:160px;
    }

    .flex span{
        float:right;
        color:#be3455;
    }
    
    #mint-warning{
        display:flex;
    }


    .grid-wrapper-pro {
        #mint-warning{
            /*display:flex;*/
            /*margin: 1rem;*/
        }
    
        #mint-warning > div {
            /*padding:5px;*/
            text-align: left;
            width:fit-content;
        }
        
    }
    
    .copyNFT{
        padding-left:5px;
    }
    
    .copyNFT:hover{
        cursor: pointer;
    }
    
    /*VERIFY */
    
    .verify-form{
        
        max-width: 540px !important;
        margin-bottom:20px;
    }
    
    .form-bold{
        font-weight: bold ;
    }
    
    #protect-error{
        display: none;
    }
    
    .protect-text {
        font-size: 20px;
        font-weight: 500;
        text-align: 'center'
    }

    .gray_text{
        color:#9b9b9b !important;
    }
    
    /* UPLOAD INPUT*/
    .drop-container {
        position: relative;
        display: flex;
        gap: 10px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 80px; /*200*/
        max-width:  540px;
        padding: 20px;
        border-radius: 10px;
        border: 1px dashed #CECECE;
        color: #444;
        cursor: pointer;
        transition: background .2s ease-in-out, border .2s ease-in-out;
        margin-bottom:20px;
    }
    .drop-container:hover {
        background: #eee;
        border-color: #111;
    }
    
    .drop-container:hover .drop-title {
        color: #222;
    }
    
    .drop-title {
        color: #444;
        font-size: 14px;
        font-weight: bold;
        text-align: center;
        transition: color .2s ease-in-out;
    }
    
    .ts-form{
        margin-top:26px;
    }
    
    /*
    form label{
        margin-bottom: 1rem !important;
    }*/
    
    .bottom{
        margin-bottom: 1rem !important;
    }

    #timestamp-nft{
        width:360px;
    }
    
    #timestamp-nft:disabled{
        background-color: #b3b3b3;
    }
    
    #timestamp-nft:disabled:hover{
        cursor:not-allowed
    }
    
    /* File name */
    #fileName{
        /*margin-top: 16px;*/
    }
    
    /* FORMS */
    .requiredMark{
        color:red;
    }
    
    .form fieldset:disabled label{
        color:#9b9b9b;
    }
    
    .form fieldset:disabled *{
        cursor: not-allowed;
    }
    
    /* PRO SECTION */

    .details-pro-section h2{
        font-size: 30px;
    }
    
    .details-pro-section {
        margin-bottom:60px;
    }
    
    /* ABOUT */
    
    
    .col-content-about {
        padding: 5rem;
        padding-bottom: 8rem;
        margin-bottom:50px;
        background-size: contain;
    }
    
    .col-content-about {
        padding: 5rem;
        padding-bottom: 8rem;
        margin-bottom:50px;
        background-size: contain;
    }
    
    .col-content-about .footer{
        text-align: center;
        align-items: center;
        width: auto;
    } 
    
    /* HISTORY */
    
    .table-history{
        /*width:80%;*/
        margin: auto;
        margin-top: 2rem;
        background-color: white;
    }
    
    .table-image{
        height: 68px;
        width:68px;
        border: 1px solid #ddd !important;
        border-radius: 10px !important;
        object-fit: cover;
    }
    
    .table-tiny td {
        width:auto !important;
    }
    .table-tiny tbody tr:hover {
        background-color:  #FAFAFA;
        cursor: pointer;
    }
    
    .table-tiny tbody td {
        padding: 0.8rem;
    }
    
    /* VERIFY PRO*/
    
    #verify_selector{
        margin-top: 1rem;
        margin-bottom: 1rem;
        color:black;
        background-color: white;
    }

    .col-content-verify {
        display:flex;
    }
    
    
    // -------------------------------------------------------------------------- //
    // PRO 
    //--------------------------------------------------------------------------- //
    
    .ProDefault{
        background-color: #ece6e6;
        margin:0;
        padding:0;
    
    }

    /* SEARCH ICON */

    .css-4302v8{
        width:auto !important;
    }
    
    /* NAVIGATION BAR - PRO */
    
    .navbar {
        background: #ffffff;
        grid-area: nav;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 30px 0 30px;
    }
    
    .navbar .site-navigation {
        position: absolute;
        top : 1rem;
        right: 2rem;
        display: flex;
        align-items: center;
        gap: 1rem;
        z-index: 1;
        }
        
    .nav_icon {
        display: none;
    }
    
    .nav_icon > i {
        font-size: 26px;
        color: #a5aaad;
    }
    
    .navbar__left {
        display: none;
    }

    .navbar__left > a {
        margin-right: 30px;
        text-decoration: none;
        color: black;
        font-size: 15px;
        font-weight: 700;
    }
    
    .navbar__right {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .navbar__right > a {
        margin-left: 20px;
        text-decoration: none;
        color: black;
        font-size: 15px;
        font-weight: 700;
    }
    
    .navbar__right > a:hover {
        color:#be3455;
    }
    
    .navbar__left > a:hover {
        color:#be3455;
    }
    
    .navbar__right > a > i {
        color: #a5aaad;
        font-size: 16px;
        border-radius: 50px;
        background: #ffffff;
        box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
        padding: 7px;
    }
    
    @media only screen and (max-width: 978px) {
        .nav_icon {
            display: inline;
        }

        #logo-mobile{
            display: block;
        }

        #logo-mobile #logoIcon{
            width: 62px;
        }

        .navbar{
            padding:0px;
        }
    }
    
    @media only screen and (max-width: 670px) {
        .navbar__left {
            display: none;
        }
    }
    
    /* ABOUT */
    .about__cards{
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
    }

    .about__cards .cards {
        /*width:500px; */
        height:fit-content;
        margin-top: 2rem ;
        text-align: justify;
        /*padding-right: 2rem;*/
    }

    .about__cards .cards h3 {
       font-size: x-large;
       font-weight:500;
    }

    .about .main__greeting p{
        text-align: justify;
        
    }

    @media only screen and (min-width: 1441px) {
        .about__cards .cards {
            width:50%; 
            height:fit-content;
            margin-top: 2rem ;
            text-align: justify;
            padding-right: 2%;
        }
        .about .main__greeting {
            padding-right: 2%;
        }
    }


    /* PROTECT */
    .protect__cards .cards {
        min-width:250px !important;
        display: flex;
        height:fit-content;
    }

    .protect__cards {
        /*display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;*/
    }

    .protect__cards .verify-form {
        /*width:360px !important; */
        height:fit-content;
       /* max-height: 40px;*/
    }

    .form textarea {
        /*min-height: 80px !important;*/
    }

    #brand-desc, #item-desc, #author-desc{
        min-height: 60px !important;
    }


    .auth_state{
        margin-top:20px;
    }

    .pro-time-btn{
        width:100%;
        padding-top:20px;
        padding-bottom: 20px;
        /*text-align: center;*/
        
    }


    /* NAVIGATION */
    
    .notifications .bubble {
        /*position: absolute;*/
        top: 1px;
        height: 18px;
        width: 18px;
        border-radius: 50%;
        text-align: center;
        line-height: 18px;
        background: var(--red);
        color: #ffffff;
    }
    
    /* SIDEBAR - PRO */
    #sidebar {
        font-weight: 600;
        background: url("../img/bg-pro.jpg") no-repeat right center;
        background-size: cover;
        grid-area: sidebar;
        overflow-y: auto;
        padding: 3rem 130px 3rem 100px;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        position: relative;
        padding-top: 140px;

        .visual-footer {
            position: absolute;
            bottom: 2rem;
            left: 0;
            padding: 0 6rem 0 3rem;
            width: 100%;
            text-align: center;
            font-weight: 400;
            font-size: 16px;
            color: #ffffff;
        }
    }

    .row .sidebar_logout{
        text-align: right !important;
    }

    .sidebar__profile {
        margin-bottom: 1rem;
    }
        
    .sidebar__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #f3f4f6;
        margin-bottom: 20px;
        position: absolute;
        top: 0;
        left: 0;

        img {
            width: 100px !important;
            height: auto;
            max-width: none;
        }
    }

    .sidebar__user {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: rgba(0,0,0,.8);
        padding: 2rem;
    }
        
    .sidebar__img {
        display: flex;
        align-items: center;
    }
        
    #logoIcon {
        width: 60px;
        object-fit: contain;
    }
    
    #logoTitle {
        padding-left:20px;
    }
        
    .SidebarList {
        height:auto;
        padding:0;
        width:100%;
        background: rgba(0,0,0,.6);
        padding: 1rem 0;
    }
    
    .SidebarList a {
        display:flex;
        width: inherit;
        text-transform: uppercase;
    }
    
    .SidebarList .row {
        width: 100%;
        list-style-type: none;
        margin:0%;
        display:flex;
        flex-direction: row ;
        color:white;
        justify-content: center;
        align-items: center;
        border: 0px solid black;
        border-radius: 5px;
        padding: .5rem 2rem;
    }
    
    .SidebarList .row:hover {
        cursor: pointer;
        // background: rgba(62, 161, 117, 0.3);
        color: #be3455;
    }
    
    .SidebarList #active{
        // background: rgba(62, 161, 117, 0.3);
        color: #be3455;
    }
    
    .row #icon {
        flex:30%;
        display:grid;
        place-items: center;
    }
    
    .row #title {
        flex:70%;
        text-align: initial;
    }
    
    .sidebar_responsive {
        display: inline !important;
        z-index: 9999 !important;
        left: 0 !important;
        position: absolute;
    }
    
    .sidebar__line{
        height:5px;
        width:100%;
        background-color:#be3455;
        margin:2px 0px;
    }
    
    #user__profile img{
        height: 95px;
        justify-content: center;
    }
    
    #user__profile{
        display:flex;
        justify-content: center;
    }
    
    #user__title{
        color:white
    }
    
    #user__title h1{
        font-size: 30px;
    }
    
    #user__title p{
        font-size: 15px;
        padding-bottom:10px;
    }
        
    @media only screen and (max-width: 978px) {
        #sidebar {
            display: none;
        }
        
        .sidebar__title > i {
            display: inline;
        }
    }
    
    /* MAIN TABS - PRO */
    
    .main__tab {
        margin: 20px 0px;
    }
    
    .search__data{
        display:flex;
    }
    
    #dashboard__selector {
        margin: 1rem 0.1rem;
        color:black;
        background-color: white;
        width: 300px;
    }
    
 
    
    .dashboard__cards {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .dashboard__cards .card {
        width:240px; 
        height:295px;
        margin: 1rem;
    }
    
    .dashboard__cards .card img{
        height: 180px;
        width:100%;
        border: 1px solid #ddd !important;
        object-fit: cover;
        margin-top: 10px;
    }


    .clickable:hover {
        cursor: pointer;
    }
    
        .text-primary-p {
            color: black;
            font-size: 18px;
            font-weight: 700;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .text-primary-p-author {
            color: #a5aaad;
            font-size: 14px;
            font-weight: 500;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        
    .mint__card{
        display:inline-block;
        color: white;
        background-color:#be3455;
        width:100px;
        padding: 5px;
        margin:auto;
        margin-top:10px;
        border-radius: 5px;
    }
    
    .main__list button{
        font-weight: 500;
        font-size: large;
    }
    
    .main__list button :disabled{
        background-color: white !important;
    }
    
    .css-1oezttv[aria-selected=true], .css-1oezttv[data-selected] {
        color: #be3455 !important;
        border-color: currentColor;
    }
    
    /* ONBOARDING */
    
    .form-onboarding {
        background-color: white;
        border-radius: 5px;
        padding:2rem;
    }
    
    .msg__board {
        flex-wrap: wrap;
        display:flex;
        padding: 1rem;
        background-color: rgb(250, 74, 74);
        text-align: center;
        justify-content: center;
        height: fit-content;
    }
    .msg__board p {
        padding-left: 5px;
        font-weight: 500;
        color: white;
    }
    
    #onboarding__filedset{
        width:100%;
    }
    
    //-----------------------------
    
    main {
        background: #ffffff;
        grid-area: main;
        overflow-y: auto;
    }
        
        .main__container {
        padding: 20px 4rem;
        }

        #dash{
            padding: 0px 4rem;
        }
        
        .main__title {
        display: flex;
        align-items: center;
        }
        
        .main__title > img {
        max-height: 100px;
        object-fit: contain;
        margin-right: 20px;
        }
        
        .main__greeting > h1 {
        font-size: 48px;
        margin-bottom: 5px;
        }
        
        .main__greeting > p {
        /* font-size: 14px;
        font-weight: 700; */
        /*color: #a5aaad;*/
        margin-bottom: 20px;
        text-align: left;
        font-size: 16px;
        max-width: 700px;
        }
        
        .main__cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 30px;
        margin: 20px 0;
        }
        
        .card {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 70px;
        padding: 25px;
        border-radius: 5px;
        background: #ffffff;
        box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
        }
        
        .card_inner {
            /* display: flex;*/
            align-items: center;
            height:85px;
        }

        .card_inner_mint {
            align-items: center;
            height:40px;
        }
        
        .card_inner > span {
        font-size: 25px;
        }
        
        
    
        @media only screen and (max-width: 855px) {
        .main__cards {
            grid-template-columns: 1fr;
            gap: 10px;
            margin-bottom: 0;
        }
        
        .charts {
            grid-template-columns: 1fr;
            margin-top: 30px;
        }
        }
    
        //---------------index.css
        @import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");
    
    * {
        margin: 0;
        padding: 0;
    }
    
    body {
        box-sizing: border-box;
    }
    
    
    .font-bold {
        font-weight: 700;
    }
    
    .text-title {
        color: #2e4a66;
    }
    
    
    .text-green {
        color: #3b9668;
    }
    
    .container {
        display: grid;
        height: 100vh;
        grid-template-columns: 35% 1fr 1fr 1fr;
        grid-template-rows: 0.2fr 3fr;
        grid-template-areas:
        "sidebar nav nav nav"
        "sidebar main main main";
    }

    #dashboard__search{
        width:unset ;
    }
    
    @media only screen and (max-width: 978px) {
        .container {
        grid-template-columns: 1fr;
        grid-template-rows: 0.2fr 3fr;
        grid-template-areas:
            "nav"
            "main";
        }
    }
    
    @media screen and (min-width: 1021px) {
    
        #basic-nav {
            background: white;
            width: 48%;
            margin-right: 2%;
            padding:32px 0 1rem 1rem;
        }
      
    }
    
    
    @media screen and (max-width: 1020px) {
        
        .footer {
            padding: 2.5rem 1rem 1.5rem;
            font-size: 12px;
            color: #ffffff;
            background: url('../img/bg-mobile.svg') no-repeat center top;
            background-size: cover;
            bottom: 0;
        }
        
        .site-header {
            box-sizing: border-box;
            height: 70px;
            width: 100%;
            background-color: white;
            display:block;
        }

        #logo-basic{
            height:70px;
            width:70px;
        }
    
        .site-navigation {
            top: .75rem;
            right: 1rem;
            background: none;
        }

        .contain-form{
            min-width: auto; /*300*/
            padding: 1rem;
            height:auto;
        }
        
        .verify-form, .drop-container {
            width: 100% !important;
        }
        
        .details-pro-section h2 {
            font-size: 38px;
        }
    }
    
    @media screen and (max-width: 670px) {
        .nav {
        display: none;
        }
        .nav .icon  {
        display: block;
        }
    }

    @media only screen and (max-width: 420px) {
        .nft-section .col .label {
            min-width: 140px;
        }

    }

    @media only screen and (max-width: 422px) {

        #dashboard__search{
            margin-left:0px !important;
        }

        .navbar__right.site-navigation{
            margin-right:0px;
        }
    }
    
    @media only screen and (max-width: 378px) {

        .main__list button{
            font-size:smaller;
        }

        .dashboard__cards .card {
            margin: 1rem 0rem;
        }

    }

    @media only screen and (max-width: 478px) {

        .hr-lines:before,
        .hr-lines:after{
            width: 120px;
        }
    }


    
    