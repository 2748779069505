/*Timestamp PRO css*/
.timestampForm {
    width: 50%;
    display: inline-block;
}

@media screen and (max-width:1000px) {
    .timestampForm {
        width: 100%;
        display: block;
    } 
}


.App {
  text-align: left;
  font-family: 'Saira', sans-serif;
  color: #231F20;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


body {
  --primaryColor: #be3455 !important;
  --red: #EB4B64 !important;
  --br: 6px;
  width: 100%;
  font-family: 'Saira', sans-serif;
  font-size: 16px;
}

body * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

/* HEADER */

.site-header {
  display: flex;
  width: 100%;
  align-items: center;
}

.site-header .title {
  font-size: 48px;
  color: var(--primaryColor);
  text-transform: uppercase;
  margin-left: 2rem;
}


/* BUTTONS */

.btn-primary {
  background-color: #231F20;
  color: #ffffff;
  padding: 16px 32px;
  border-radius: var(--br);
  border: 1px solid #231F20;
  cursor: pointer;
  -webkit-transition: all .3s;
  transition: all .3s;
  min-width: 200px;
}


#GoPro{
  position: absolute;
  right: 2rem;
}

#GoProOut{
  float:right;
  margin-right: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
