@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

body {
    --primaryColor: #be3455;
    --red: #EB4B64;
    --br: 6px;
    width: 100%;
    font-family: 'Saira', sans-serif;
    font-size: 16px;

    * {
        box-sizing: border-box;
    }
}

/*
p {
    word-break: break-all;
}*/

.word-wrap{
    word-break: break-all;
}

h1,
h2 {
    font-weight: 400;
    margin: 0;
}

h2 {
    font-size: 48px;
}

.btn-primary {
    background-color: #7c00ce;
    color: #ffffff;
    padding: 10px 32px;
    border-radius: var(--br);
    border: 1px solid #ffffff;
    cursor: pointer;
    transition: all .3s;

    &:hover {
    }
}

.btn-primary.btn-verify {
    width: 360px;
 
}

.btn-secondary {
    background-color: #ffffff;
    color: #231F20;
    padding: 16px 32px;
    border-radius: var(--br);
    border: 1px solid #231F20;
    cursor: pointer;
    transition: all .3s;
    
    &:hover {
    }
}

.actions {
    position: absolute;
    top: 2rem;
    right: 2rem;
    display: flex;
    gap: 1rem;

    .notifications {
        height: 52px;
        width: 52px;
        border-radius: 50%;
        background: #ffffff;
        border: 1px solid #f2f2f2;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            height: 30px;
        }

        .bubble {
            position: absolute;
            top: -8px;
            right: -8px;
            height: 22px;
            width: 22px;
            border-radius: 50%;
            text-align: center;
            line-height: 22px;
            background: var(--red);
            color: #ffffff;
        }
    }

    .add {
        height: 52px;
        width: 52px;
        border-radius: 50%;
        background: #000000;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 24px;
        }
    }
}

.bubble.auth {
    position: absolute;
    top: -8px;
    right: -8px;
    
    border-radius: 50%;
    text-align: center;
    line-height: 22px;
    color: #be3455;
    
}

.flex{
    display:flex;
}

.compressed{
    font-size: large;
}

.content-wrap {
    box-sizing: border-box;
    min-height: 100vh;
    display: flex;
    width: 100%;
    align-items: flex-start;

    &.light-bg {
        /*background-color: #f2f2f2;*/
    }
}

.w-30 { width: 30%; }

.w-40 { width: 40%; }

.w-50 { width: 50%; }

.w-60 { width: 60%; }

.w-70 { width: 70%; }

.col-visual {
    background-image: url('../img/bg.svg');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: cover;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 4rem 2rem 2rem;
    min-height: 100vh;
    position: sticky;
    top: 0;

    &-protect {
        background: url("../img/bg-protect.jpg") no-repeat right center;
        background-size: cover;
        font-family: 'Saira', sans-serif;
        font-weight: 500;
        font-size: 48px;

        @media screen and (max-width: 1020px) {
            background: url("../img/bg-protect-m.jpg") no-repeat right bottom;
            background-size: cover;
        }
    }

    &-mint {
        background: url("../img/bg-mint.jpg") no-repeat center center;
        background-size: cover;
        font-family: 'Saira', sans-serif;
        font-weight: 500;
        font-size: 48px;

        @media screen and (max-width: 1020px) {
            background: url("../img/bg-mint-m.jpg") no-repeat right bottom;
            background-size: cover;
        }
    }

    &-verify {
        background: url("../img/bg-verify.jpg") no-repeat right center;
        background-size: cover;
        font-family: 'Saira', sans-serif;
        font-weight: 500;
        font-size: 48px;

        @media screen and (max-width: 1020px) {
            background: url("../img/bg-verify-m.jpg") no-repeat center bottom;
            background-size: cover;
        }
    }

    &-about {
        background: url("../img/bg-about.jpg") no-repeat right center;
        background-size: cover;
        font-family: 'Saira', sans-serif;
        font-weight: 500;
        font-size: 48px;

        @media screen and (max-width: 1020px) {
            background: url("../img/bg-about-m.jpg") no-repeat center bottom;
            background-size: cover;
        }
    }

    &-pro {
        background: url("../img/bg-pro.jpg") no-repeat right center;
        background-size: cover;
        font-family: 'Saira', sans-serif;
        font-weight: 500;
        font-size: 48px;

        @media screen and (max-width: 1020px) {
            background: url("../img/bg-pro-m.jpg") no-repeat center bottom;
            background-size: cover;
        }
    }

    .visual-wrap {
        width: 70%;
        margin: auto;
        font-size: 48px;
        text-align: center;
        font-weight: 500;
    }

    img {
        height: 100px;
        width: auto;
        margin-bottom: 1rem;
    }

    &.light {
        background-image: url('../img/bg-light.svg');
        background-position: left center;
        color: #000000;
    }

    &.nft-details {
        display: block;
        text-align: left;
        padding-top: 8rem;
        justify-content: flex-start;
    }

    .visual-footer {
        position: absolute;
        bottom: 2rem;
        left: 0;
        padding: 0 2rem 0 2rem;
        width: 100%;
        text-align: center;
        font-weight: 400;
        font-size: 16px;
    }
}

.col-content {
    display: flex;
    /*justify-content: center;*/
    flex-grow: 1;
    text-align: justify;
    min-height: 100vh;
    padding: 110px 4%;

    &.about {
        text-align: left;
        
        h3 {
            font-size: 30px;
            margin: 0 0 1rem;
        }

        p {
            margin: 0 0 1rem 0;
            padding: 0 0 0rem;
            /*word-break: keep-all;*/
            text-align: justify;
        }

        .content {
            /*max-width: 500px;*/
        }

        .details-content {
            margin-bottom: 2rem;
            text-align: justify;
        }
    }

    &.nft-details {
        text-align: left;
        padding: 60px 4%;
    }

    &#onboarding {
        justify-content: center;
    }
}



.heading {
    margin-bottom: 3rem;

    &-step {
        color: #9B9B9B;
        font-size: 16px;
    }

    &-title {
        color: #7c00ce;
        font-size: 48px;
        font-weight: 500;
    }

    &-description {
        margin-bottom: 20px;
        font-size: 16px;
        text-align: justify;
    }

}
.heading-mint{
    &-step {
        color: #9B9B9B;
        font-size: 16px;
    }

    &-title {
        color: #231F20;
        font-size: 48px;
    }
}

.css-1xhq01z {
    border-bottom: none !important;
}

.form {
    width: inherit;

    &--dashboard {
        background: rgba(0,0,0,.03);
        padding: 1rem;
    }

    h2 {
        margin-bottom: 2rem;
        font-size:  48px;
    }

    &-group {
        margin-bottom: 1rem;
    }

    label {
        display: block;
        font-size: 12px;
        color: #9b9b9b;
        /*color: black;*/
        margin-bottom: .25rem;
    }

    input, textarea {
        box-sizing: border-box;
        border: 1px solid #CECECE;
        padding: 8px 16px;
        border-radius: var(--br);
        width: 100%;
        display: block;
    }

    &-submit-btn {
        background-color: rgba(35, 31, 32, .7);
        background-color: #231F20;
        border-radius: var(--br);
        border: 0;
        color: #ffffff;
        width: 100%;
        cursor: pointer;
    }

    &-forgot {
        text-align: right;
        font-size: 12px;
        color: #231f20;
        margin-bottom: 1.5rem;

        a {
            text-decoration: none;
        }
    }

    &-or {
        text-align: center;
        margin: 1.5rem 0;
        color: #707070;
    }

    &-auth-btn {
        display: block;
        padding: 14px 16px;
        text-align: center;
        border: 1px solid #CECECE;
        border-radius: var(--br);
        text-decoration: none;
        color: #000000;
        margin-bottom: 1rem;
        font-weight: 500;

        i {
            margin-right: .5rem;
        }
    }

    &-face-id {
        display: none;
    }

    &-signup-link {
        margin-top: 2rem;
        text-align: center;

        a {
            color: #000000;
            text-decoration: none;
        }
    }
}

.visual-heading {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.visual-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    min-width: 200px ;
    
    .active a {
        font-weight: 600;
    }
    
    a {
        color: #ffffff;
        text-decoration: none;
        font-size: 32px;
        display: block;
        padding: 2rem 0;
        border-bottom: 1px solid #ffffff;

        &:hover {
            font-weight: 600;
        }
    }
}

.cards {
    position: relative;

    &-1 {
        top: 0px;
    }
    &-2 {
        top: 30px;
    }
    &-3 {
        top: 60px;
    }
    &-4 {
        top: 90px;
    }
}

.card {
    padding: 1.5rem;
    box-shadow: 0px 13px 34px #00000029, 0 -2px 10px rgba(0,0,0,.1);
    border-radius: 15px;
    color: #ffffff;
    width: 345px;
    height: 215px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: all .3s;
    
    &:hover {
        transform: scale(1.05);
        z-index: 11;
    }

    &-1 {
        background: transparent linear-gradient(180deg, #27A5AA 0%, #76CFD3 100%) 0% 0% no-repeat padding-box;
        z-index: 10;
    }

    &-2 {
        background: transparent linear-gradient(180deg, #2955ab 0%, #3d6fd1 100%) 0% 0% no-repeat padding-box;
        z-index: 9;
        position: absolute;
        top: -60px;
        left: 0;
    }

    &-3 {
        background: transparent linear-gradient(180deg, #8915a0 0%, #c125e0 100%) 0% 0% no-repeat padding-box;
        z-index: 8;
        position: absolute;
        top: -120px;
        left: 0;
    }

    &-name {
        text-align: center;

        h3 {
            font-size: 32px;
            margin-bottom: 0;
            line-height: 1;
        }

        p {
            opacity: .5;
        }
    }
}

.details-content {

    text-align: left;

    .label {
        font-size: 11px;
    }
}


.inside {
    list-style-position: inside;
}


.card-details {
    h4 {
        font-size: 13px;
        font-weight: 400;
        opacity: .6;
    }

    &-list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            box-sizing: border-box;
            background: #ffffff;
            padding: 1rem;
            margin-bottom: 2px;
            display: flex;
            gap: 1rem;
            justify-content: space-between;

            .value {
                color: #00000066;
            }
        }

        &.verifiers {
            margin-bottom: 2rem;

            li {
                flex-direction: column;
                gap: 0;
            }
        }
    }

    .btn {
        display: block;
        width: 100%;
    }
}

.popup {
    max-width: 600px;
    text-align: center;
    background: #ffffffe1;
    backdrop-filter: blur(29px);
    -webkit-backdrop-filter: blur(29px);
    padding: 4rem;

    h3 {
        margin: 0 0 1rem;
        color: #707070;
        font-size: 28px;
        font-weight: 400;
    }

    p {
        font-size: 36px;
        font-weight: 500;
        margin: 0 0 2rem;
    }

    &-actions {
        .btn {
            margin: 0 .5rem;
        }
    }

    &-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #cecece7c;
        z-index: 1000;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.pro-link{
    color:#a7a6a6;
  }
  
/* PRO GRID */
.card__grid {
   background: rgba(0, 0, 0, 0.03); 
}

.grid-wrapper-pro {
    display:grid;
    /*grid-template-columns: 1fr 1fr;*/
    grid-template-columns: 1fr;
    grid-gap: 1em;
    grid-template-rows:  auto ;


    .grid-content-left{
        grid-column: 1/2;
        grid-gap: 1em;
        grid-auto-rows: 100%
    }

    .grid-content-right{
        grid-column: 2/3;
        grid-gap: 1em;
        grid-auto-rows: auto;
    }

    .col-visual{
        min-height: fit-content;   
    }

    .grid-content-left.nft-details{
        position: unset;
        min-height: unset;
    }

    .card{
        display: flex;
        flex-direction: column;
        height: 70px;
        padding: 10px;
        justify-content:unset;
        border-radius: 5px;
        background: #ffffff;
        box-shadow: 5px 5px 13px #ededed, -5px -5px 13px #ffffff;
    }
}

.grid-wrapper-pro.about > .col-content{
    display:initial;
} 


.grid-wrapper-pro.dashboard, .grid-wrapper-pro.main, .grid-wrapper-pro.manager , .grid-wrapper-pro.protect{
    display:grid;
    grid-template-columns: 1fr ;
    grid-gap: 1em;
    grid-template-rows:  auto ;


    .grid-content-left{
        grid-column: 1/3;
        grid-gap: 1em;
        grid-auto-rows: 100%
    }

    .grid-content-right{
        display:none
    }
}


.fixed-content{
    position:sticky;
    left:0;
    top: 100px;
}


@media screen and (max-width: 1440px) {
    #sidebar{
        padding: 3rem 70px 3rem 50px !important;
        padding-top: 140px !important;
    }

}    

@media screen and (max-width: 1040px) {

    // Details for verify
    .grid-content-left.nft-details{
        visibility:visible;
        grid-column: 1/3 !important;
        grid-row: 1/3 !important;
    }

    .grid-content-right.nft-details{
        grid-column: 1/3 !important;
        grid-row: 3/4 !important;
    }

    .grid-content-left.col-visual{
        position:unset
    }
  

    /* GRID PRO */
    .grid-wrapper-pro {
        display:grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto ;
        grid-gap: 1em;

        .grid-content-left{
            visibility:visible;
        }
        
        .grid-content-right{ 
            grid-column: 1/3;
            grid-row: 3;
        }
    
        .grid-footer{
            grid-column:1/3;
            grid-auto-rows: 30px;
            grid-row: 4;
        }

         // Details for verify
        .grid-content-left.nft-details{
            visibility:visible;
            grid-column: 1/3 !important;
            grid-row: 1/3 !important;
        }

        .grid-content-right.nft-details{
            grid-column: 1/3 !important;
            grid-row: 3/4 !important;
        }

        .grid-content-left.col-visual{
            position:unset;
            height:max-content;
        }
    }

    /* protect & sidebar */
    .protect__cards .cards.line{
        border-right: none !important;
    }

    .protect__cards .cards{
        margin:auto !important;
        /*width: 280px !important;*/
        
    }

}


@media screen and (min-width: 481px) {
    .m-show {
        display: none;
    }

}


@media screen and (max-width: 767px) {
    .nft-details-m .col {
        flex:none;
        display: block !important;
    }

    .nft-details-m .col .label{
        font-size: 18px;
        font-weight: bolder;
    }

}

@media screen and (max-width: 1020px) {
    .m-hide {
        display: none;
    }

    .popup {
        margin: 2rem;

        &-actions {
            .btn {
                display: block;
                width: 100%;
                margin-bottom: 1rem;
            }
        }
    }

    .actions {
        transform: scale(.8);
        top: 1rem;
        right: 1rem;
    }

    .content-wrap {
        flex-direction: column;
    }

    .col-visual {
        width: 100%;
        min-height: 40vh;
        padding: 2rem 2rem;
        font-size: 24px;
        margin-top: 70px;
        position: initial;

        .visual-wrap {
            width: 100%;
            font-size: 24px;
        }

        .visual-footer {
            color: #9B9B9B;
            font-size: 12px;
        }

        &.nft-details {
            display: block !important;
            padding: 6rem 2rem 1rem;
            width: 100%;
            background-position: center;
            position: unset;

            h2 {
                font-size: 32px;
                line-height: 1.2;
            }
        }

        &.light {
            width: 100%;
            display: block !important;
            padding: 2rem;
        }
    }

    .col-content {
        width: 100%;
        padding: 2rem 5% 10rem;
        // background: url(../img/bg-mobile.svg) no-repeat center bottom;
        background-size: contain;
        min-height: auto;

        .form {
            width: 100%;
        }
    }

    .logo {
        position: absolute;
        top: 0;
        left: 0;
        height: 70px;
    }

    .logged-in .logo {
        right: auto;
        left: 0;
    }

    .form h2 {
        margin-bottom: 1rem;
    }

    .form-face-id {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        margin-top: 1.5rem;
        margin-bottom: -1rem;
        text-transform: uppercase;
        text-align: center;
        font-weight: 500;

        img {
            width: 42px;
        }
    }

    .form-or {
        margin: 1rem 0;
    }

    .page-wallet {

        h2 {
            text-align: center;
            margin-bottom: 2rem;
        }

        .col-content {
            padding-top: 30%;
            padding-bottom: 20%;
            flex-direction: column;
            justify-content: space-between;
        }

        .btn-primary {
            width: 100%;
        }
    }

    .page-details {
        .col-content {
            background: none;
            padding-top: 6rem;
        }
        .col-visual {
            margin-top: -17rem;
            padding-top: 15rem;
            background-position: center center;
        }
    }

    /*pro version*/
    .about__cards .cards{
        width:auto !important;
        text-align: justify;
    }

    /* Hide notification bell for managers*/
    .notifications{
        display:none !important;
    }
}

@media screen and (max-width: 426px) {
    .btn-primary {
        min-width: none;
        width: 100%;
    }

    .main__container{
        padding: 0.5rem !important;
        width:100%;
    }

    /*pro version*/
    #timestamp-nft{
        width:auto !important;
    }

    .form .btn-primary{
        width:auto;
    }
}


@media screen and (max-width: 485px) {
        
    #mint-warning{
        display:block !important;
    }
}
