.p, button {
   margin: 10px 0;
}

.error_text {
    margin-top: 5px;
}

.text_bolder {
    font-weight: bolder;
}

.success_text {
    color: green;
}

.fail_text {
    color: red;
}

.info_text {
    color: cornflowerblue;
}